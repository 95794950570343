<template>
  <div>
    <transition name="fade">
      <div class="inav bg row row-no-gutters" style="height: 57px;">
        <div style="margin-top: 8px;margin-left: 15px">
          <b-img
              src="../assets/images/home_slices/Group 1761.png"
              alt="广东壹公里数智科技有限公司"
              style="max-width: 100%; max-height: 100%"
          ></b-img>
        </div>
        <div style="margin-left: auto; margin-right: 80px">
          <div style="display: inline; margin-top: 16px">
            <p class="ppclass">官网</p><p style="color: #DCE0E4; display: inline-block">|</p><p class="ppclass" style="margin-left: 10px">用户声音</p><p style="color: #DCE0E4; display: inline-block">|</p><p class="ppclass" style="margin-left: 10px">提交工单</p>
          </div>
          <button data-v-88259c56="" type="button"
                  style="background: linear-gradient(to right, #2840d4, #399ff7);
                color: white;
                border-radius: 25px;
                font-size: 13px;
                width: 70px;
                height: 30px;
                margin-top: 14px;
                margin-left: 10px"><span>登录</span></button>
        </div>
      </div>
    </transition>

    <header style="position: relative;">
      <div style=" margin: 0 auto;">
        <img style="width: 100%;height: 100%;" src="../assets/images/bossdoc/Group 2267.png"/>
      </div>
      <div class="boss-content" style="z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;">
        <div style="position: relative;left: 58px">
          <div class="boss-content">
            <h1 style="font-size: 50px;"><b>文档中心</b></h1>
            <p style="font-size: 20px;">丰富权威的官方文档，祝你快速上云</p>
            <br>
          </div>
        </div>
      </div>
    </header>
    <main style="background: rgba(255, 255, 255, 255)">
      <div class="boss-content">
        <div data-v-3a54fec3="" style="margin-top: 30px;">
          <div data-v-3a54fec3="" class="el-row ctop" style="margin-left: -20px; margin-right: -20px;">
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-11">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/Group 2258.png"
                               class="image-solution-style"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; margin-top: 10px;white-space: nowrap;display: inline;">
                            <p class="pclass">BossDI</p>数据中台
                          </h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            对海量数据进行采集、加工</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><a href="http://47.112.216.86:3000/#/bossdi/bossdi" style="text-decoration: none;" target="_blank"><p style="margin-right: 10px;display: inline">查看文档</p> →</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-5">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/Group 2258(3).png"
                               class="image-solution-style"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; margin-top: 10px; white-space: nowrap; display: inline;">
                            壹公里应用系统<p class="pclass">集成平台</p></h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            快速实现企业级应用集成</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><p style="margin-right: 10px;display: inline">查看文档</p> →</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br data-v-3a54fec3=""><br data-v-3a54fec3="">
          <div data-v-3a54fec3="" class="el-row ctop1" style="margin-left: -20px; margin-right: -20px;">
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-5">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div2"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/10 - BossBI_Logo@2x.png"
                               class="image-solution-style2"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; white-space: nowrap; display: inline;">
                            <p class="pclass">BossBI</p>数据设计中心
                          </h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            提升企业主数据管理水平</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><a href="http://47.112.216.86:3000/#/bi/bi" style="text-decoration: none;" target="_blank"><p style="margin-right: 10px;display: inline">查看文档</p> →</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-5">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/Group 2258(4).png"
                               class="image-solution-style"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; white-space: nowrap; display: inline;">
                            <p class="pclass">BossMa</p>低代码平台
                          </h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            支持个性化差异定制</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><a href="http://47.112.216.86:3000/#/bossma/bossma" style="text-decoration: none;" target="_blank"><p style="margin-right: 10px;display: inline">查看文档</p> →</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br data-v-3a54fec3=""><br data-v-3a54fec3="">
          <div data-v-3a54fec3="" class="el-row ctop2" style="margin-left: -20px; margin-right: -20px;">
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-5">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/Group 2258(2).png"
                               class="image-solution-style"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; white-space: nowrap; display: inline">
                            壹公里<p class="pclass">企业门户</p></h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            一站式协同平台</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><p style="margin-right: 10px;display: inline">查看文档</p> →</div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-12"
                 style="padding-left: 20px; padding-right: 20px;">
              <div data-v-3a54fec3="" class="grid-content2 bg-purple-light">
                <div data-v-3a54fec3="" class="myrow el-row">
                  <div data-v-3a54fec3="" class="product-solution-mycol el-col el-col-24 el-col-md-5">
                    <div data-v-3a54fec3="" class="el-row" style="margin: 0px;">
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-8"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="float-solution-peculiarity-div"
                             style="padding-right: 20px; margin-left: 20px; text-align: center; height: 100px; width: 50%;">
                          <img data-v-3a54fec3="" src="../assets/images/bossdoc/Group 2258(5).png"
                               class="image-solution-style"></div>
                      </div>
                      <div data-v-3a54fec3="" class="el-col el-col-24 el-col-md-16"
                           style="padding-left: 5px; padding-right: 5px;">
                        <div data-v-3a54fec3="" class="title-solution">
                          <h1 data-v-3a54fec3=""
                              style="font-size: 23px; font-weight: bold; white-space: nowrap; display: inline">
                            <p class="pclass">IoT</p>智能物联网平台
                          </h1>
                          <p data-v-3a54fec3=""
                             style="font-size: 18px;">
                            提升企业主数据管理水平</p></div>
                      </div>
                    </div>
                    <div data-v-3a54fec3="" class="dclass"><p style="margin-right: 10px;display: inline">查看文档</p> →</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
    <footer>
      <div style="margin-top: -30px">
        <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bossdoc/Rectangle 30@2x.png"/>
      </div>
      <div class="boss-content" style="z-index: 9;
              position: absolute;
              top: -30px;
              left: 0;
              right: 100px;
              bottom: 135px;
      ">
        <img style="width: 20%;height: 20%;object-fit: contain; margin-top: 70px"
             src="../assets/images/bossdoc/Logo_Color(1).png"/>
        <div style="position: relative;left: 58px; margin-top: -10px">
          <div class="boss-content">
            <b-row class="footer2">
              <b-col md="12">
                <h3 style="font-size: 18px; margin-top: 20px; margin-bottom: 20px; display: inline"><b>广东壹公里数智科技有限公司</b>
                  <img
                    src="../assets/images/home_slices/call.png"
                    style="
                        width: 20px;
                        height: 20px;
                        top: 0;
                        margin-left: 572px;
                        margin-right: 10px;
                        position: relative;
                      "
                /><p style="display: inline-block;
                            font-size: 19px;
                            font-weight: 600;
                            width: 152px;
                            color: #1b212e;
                            margin: 0px;
                            font-family: SF Pro Display-Semibold, SF Pro Display;">0757-83785031</p></h3>
              </b-col>
              <b-col md="6" lg="4" sm="6">
                <dl>
                  <dd>联系邮箱:&nbsp;&nbsp;&nbsp;chendanqing@bossrepost.net</dd>
                  <dd>电话/传真:&nbsp;&nbsp;&nbsp;075783785031</dd>
                </dl>
              </b-col>
              <b-col md="6" lg="3" sm="6">
                <dl>
                  <dd>联系电话:&nbsp;&nbsp;&nbsp;18680037498</dd>
                  <dd>QQ:&nbsp;&nbsp;&nbsp;&nbsp;842823494</dd>
                </dl>
              </b-col>
              <b-col md="12" lg="5" sm="12">
                <dl>
                  <dd>
                    联系地址:&nbsp;&nbsp;&nbsp;广东省佛山市南海区桂城街道金科路6号粤港金融科技园1座8层
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row style="margin-top: -10px">
              <b-col md="12">
                <div class="zoomlaFoot">
                  版权所有@ 2024 广东壹公里数智有限公司 <a href="https://beian.miit.gov.cn" target="_blank" style="color:#8085ad;">粤ICP备19057806号</a>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
export default {
  name: "BossBoc",
  data() {
    return {}
  }
};
</script>

<style lang='scss' scoped>
.ppclass {
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}
.zoomlaFoot {
  height: 32px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 32px;
  padding: 30px 0;
}
.footer2 {
  padding: 20px 0;
  border-bottom: 1px solid rgba(90, 96, 111, 0.48);

  & > * {
    margin-top: 10px;
  }

  dd {
    font-size: 12px;
    padding: 2px 0;
  }
}
.dclass {
  margin-right: 30px;
  margin-left: 10px;
  float: right;
  color: #0139d0;
}

.pclass {
  color: #070f75;
  display: inline-block;
}

.grid-content2 {
  border-radius: 4px;
  min-height: 36px;
  width: 480px;
}

.bg-purple-light {
  background: white;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.ctop {
  top: -60px;
}

.ctop1 {
  top: -100px;
}

.ctop2 {
  top: -140px;
}

.solution-myrow {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  height: 250px;
  width: 100%;
}

.myrow {
  //box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  box-shadow: 0px 4.4px 10px rgba(0, 0, 0, 0.03),
  0px 35px 80px rgba(0, 0, 0, 0.06);
}

.product-solution-mycol {
  width: 100%;
  height: 180px;
}

.float-solution-peculiarity-div {
  position: relative;
  top: 30px;
  left: 10px;
  z-index: 1;
  margin: 0;
}

.float-solution-peculiarity-div2 {
  position: relative;
  top: 40px;
  left: 10px;
  z-index: 1;
  margin: 0;
}

.image-solution-style {
  height: 80px;
  width: 80px;
}

.image-solution-style2 {
  height: 40px;
  width: 80px;
}

.title-solution {
  width: 100%;
  margin: 30px 10px 30px 5px;
}
</style>